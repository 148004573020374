import React from 'react'
import * as classes from '../../styles/infoCard.module.scss'
import Typography from '../../components/Typography/Typography';

export default function InfoCard({title, infoTitle, discription, infoText, img}) {
  return (
    <div className={classes.root}>
    <Typography variant="h1" align="center">{title}</Typography>
      {discription && 
        <p className={`${classes.description} ${classes.titleInfo}`}>
          {discription}
        </p>
      }
      <div className={classes.detilsSection}>
        <img className={classes.illustration} src={img} alt="als" />
        <div>
          {infoTitle && <h3 className={classes.infoTitle}>{infoTitle}</h3>}
          <p className={classes.description}>
            {infoText}
          </p>
        </div>
        
      </div>
    </div>
  )
}
