// extracted by mini-css-extract-plugin
export var whyWeSection = "WhyWeSection-module--whyWeSection--rd17P";
export var infoTitle = "WhyWeSection-module--infoTitle--t+sw6";
export var title = "WhyWeSection-module--title--68-2g";
export var description = "WhyWeSection-module--description--uW4wm";
export var detailsSection = "WhyWeSection-module--detailsSection--OZUr3";
export var illustration = "WhyWeSection-module--illustration--aXu4e";
export var textAlign = "WhyWeSection-module--textAlign--p2eqa";
export var root = "WhyWeSection-module--root--RIHxn";
export var titleInfo = "WhyWeSection-module--titleInfo--3ZlE-";
export var detilsSection = "WhyWeSection-module--detilsSection--dbwtN";