import React from 'react';
import Typography from '../Typography/Typography';
import * as classes from './WhyWeSection.module.scss';

import imgsrc from '../../images/whywe.svg';

export default function WhyWeSection() {
  return (
    <div className={classes.whyWeSection}>
      <Typography variant="h1" align="center">
        Why Join Experts Circle?
      </Typography>

      <div className={classes.detailsSection}>  
        <Typography variant="p">
          Has the hiring process become stagnant, unchanged and unchallenged for decades? What if there was a transformative way forward? 
          Imagine empowering field experts to identify and recommend ideal candidates based on real knowledge of the job's demands. Move beyond traditional methods, 
          which often overlook specific needs, and explore the potential of connecting talent through trusted, verified experts who understand precisely what is required.
        </Typography>    
        <br/>          
        <Typography variant="p">
          <h3 className={classes.textAlign}>Experts</h3>
          <p><b>Become a Trusted Expert</b>: Leverage your industry knowledge and professional connections to create new opportunities for yourself and others. 
            This role is designed to complement your existing career, offering a way to grow professionally without disrupting your primary job.</p>
            <ul>
            <li><b>Get Rewarded for Your Expertise:</b> Earn the majority of the recruitment fee for successful placements. Your insights and effort are recognized, ensuring meaningful compensation that reflects your contributions.</li>
            <li><b>Enhance Your Professional Profile:</b> Establish yourself as a leader in your industry by connecting top talent with leading employers. Your role as a trusted expert amplifies your reputation and career prospects.</li>
            <li><b>Make an Impact:</b> Shape careers and drive success by placing the right people in the right roles. Your expertise helps industries grow, creating a lasting legacy in your field.</li>
            <li><b>Seamlessly Integrate with Your Career:</b> Engage with the platform on your terms. This flexible role is designed to complement your existing career, allowing you to grow professionally without disruption.</li>
          </ul>
        </Typography>  
        <br/>
        <Typography variant="p">
          <h3 className={classes.textAlign}>Employers</h3>             
          <b>Trust in Expertise</b>: Rely on the insights of experienced industry specialists who understand not only the required skills but also the importance of aligning candidates' professional ethos with your company culture. Our platform enhances your hiring process by:
          <ul>
            <li><b>Curated Talent Pools: </b> Access a handpicked selection of candidates whose skills and experiences have been rigorously verified by industry experts.</li>
            <li><b>Efficient Hiring: </b>Streamline your hiring process by reducing the clutter of unrelated applications and focusing on precision-matched candidates ready to excel in your roles.</li>
            <li><b>Integrated Workflow: </b> Manage every aspect of your hiring activities in one secure, user-friendly platform, from posting opportunities to signing contracts, saving you time and ensuring compliance.</li>
          </ul>          
        </Typography>   
        <br/>
        <Typography variant="p">
          <h3 className={classes.textAlign}>Candidates</h3>
          <b>Your Next Opportunity, Handpicked by Experts</b>: At Experts Circle, we ensure that every opportunity presented to you is carefully vetted by an industry expert who understands your potential and aligns it with roles that match your aspirations. Our platform supports you by:
          <ul>
            <li><b>Personalised Opportunities:</b> Receive job recommendations tailored to your skills, experience, and career goals.</li>
            <li><b>Trusted Endorsements:</b> Benefit from being recommended by an industry expert who can advocate for your strengths and capabilities.</li>
            <li><b>Streamlined Process:</b> Enjoy a seamless application process, from initial recommendations to final contract signing, all in one place.</li>
          </ul>

        </Typography>
      </div>
      <br/>
      <Typography variant="p">
        <h4>Together, We Redefine Hiring:</h4>          
        At Experts Circle, we blend cutting-edge technology with the insights of industry professionals to create a talent-matching ecosystem that is efficient, reliable, and innovative. Whether you're an employer seeking top-tier talent, a job seeker looking for your next opportunity, or an expert ready to leverage your knowledge to connect people with success, Experts Circle is your platform where expertise drives progress.
      </Typography>        
    </div>
  );
}
