import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { navigate } from "gatsby";
import Lottie from "lottie-react";

import roketAni from "../../lotti_json/roket_ani.json";
import roket2Ani from "../../lotti_json/roket2.json";
import lashAni from "../../lotti_json/lash.json";
import freeAni from "../../lotti_json/free.json";
import * as classes from "../../styles/expert_home.module.scss";
import HowItWorkSection from "./HowItWorkSection";
import CallToAction from "./CallToAction";
import Layout from "../../components/Layout/Layout";
import SEO from "../../components/Seo";
import TopRatedExperts from "./TopRatedExperts";
import { getDomainURL, recruiterUserType, candidateUserType } from "../../constants";

const featureData = [
  {
    ani: lashAni,
    title: "Leverage Your Network",
    description: "Help professionals in your circle discover the right opportunities and support companies in finding exceptional talent",
  },
  {
    ani: roketAni,
    title: "Be a Game Changer",
    description: "Redefine how opportunities are shared by bridging the gap between talent and businesses using your unique expertise.",
  },
  {
    ani: roket2Ani,
    title: "Earn as You Contribute",
    description: "Be rewarded for every successful match you facilitate. The majority of the fee goes to you, ensuring fairness for all",
  },
  {
    ani: freeAni,
    title: "Effortless Hiring",
    description:
      "Our platform takes care of the complex hiring steps, making it seamless for you",
  },
];

const ExpertHome = () => {
  // Separate useState calls for each state variable
  const [isSignUp, setIsSignUp] = useState(true);
  const [isRecruiter, setIsRecruiter] = useState(true);
  const [invitationId, setInvitationId] = useState('');

  useEffect(() => {
    if (typeof window !== "undefined") {
      const searchParams = new URLSearchParams(window.location.search);
      const signUpParam = searchParams.get("signup");
      const isRecruiterParam = searchParams.get("isRecruiter");
      const invitation = searchParams.get("invitationId");
  
      // Only update if the URL has these parameters, otherwise keep default
      if (signUpParam) {
        setIsSignUp(signUpParam === "true");
      }
      if (isRecruiterParam) {
        setIsRecruiter(isRecruiterParam === "true");
      }

      if(invitation){
        setInvitationId(invitation);
      }
    }
  }, []);

  const buttonLabel = isSignUp ? "Get Started" : "Login";

  const handleButtonClick = () => {
    if (typeof window !== "undefined") {
      const baseUrl = getDomainURL(window.location, candidateUserType);
      const url = new URL(baseUrl);

      // Simplified userType setting based on isRecruiter state
      url.searchParams.set("userType", isRecruiter ? recruiterUserType : candidateUserType);
      
      // Directly setting 'signup' parameter based on isSignUp state
      url.searchParams.set("signUp", isSignUp ? "true" : "false");



      url.searchParams.set("invitationId", invitationId);

      // Redirect the user to the modified URL
      window.location.href = url.toString();
    }
  };

  return (
    <Layout mode="expert_home">
      <SEO title="Home" />
      <div className={classes.section1}>
        <div className={classes.heroSection}>
          <div className={classes.textContent}>
            <h1 className={classes.mainText}>
            Transform Your Expertise Into an Opportunity to Connect Talent with Success            
            </h1>
            <p className={classes.moreInfo}>
            Use your knowledge, skills, and network to match top professionals with meaningful opportunities. Earn while creating real value for businesses and individuals.
            </p>
            <div className={classes.m_yVideo}>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/MCdfF-fiRG4?rel=0&controls=1"
                title="Introducing Experts Circle"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
            <div className={classes.actions}>
              <Button
                onClick={handleButtonClick}
                className={classes.signInWithLinkedInBut}
                variant="contained"
                color="secondary"
              >
                {buttonLabel}
              </Button>
              <Button
                onClick={() => navigate("/how_it_works")}
                className={classes.learnMoreBut}
                variant="outlined"
                color="secondary"
              >
                Learn More
              </Button>
            </div>
          </div>
          <div className={classes.yVideo}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/MCdfF-fiRG4?rel=0&controls=0"
              title="Introducing Experts Circle"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        <div className={classes.featureContainer}>
          <h2 className={classes.expertText}>Why Become an Expert?</h2>
        <div className={classes.features}>    
          {featureData.map(({ ani, title, description }) => (
            <div className={classes.featureCard} key={title}>
              <div>
                <Lottie
                  background="transparent"
                  speed="1"
                  style={{ width: 130, height: 130 }}
                  loop
                  autoPlay
                  animationData={ani}
                />
              </div>
              <div>
                <h3>{title}</h3>
                <p>{description}</p>
              </div>
            </div>
          ))}
        </div>
        </div>
      </div>

      <HowItWorkSection />
      <CallToAction onButtonClick={handleButtonClick} />
      <TopRatedExperts />
    </Layout>
  );
};

export default ExpertHome;
