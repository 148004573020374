import { Link } from 'gatsby';
import React from 'react';

import logo from '../../../images/logo.svg';
import FacebookIcon from './Facebook';
import * as classes from './footer.module.scss';
import LinkedinIcon from './Linkedin';
import TwitterIcon from './Twitter';
import Typography from '../../Typography/Typography';

const helpLinks = [
  {
    title: 'Company',
    items: [
      {
        label: 'About Us',
        url: '/about',
      },
      {
        label: 'How It Works',
        url: '/how_it_works',
      },
      {
        label: 'FAQ',
        url: '/faq',
      },
    ],
  },
  {
    title: 'Legal',
    items: [
      {
        label: 'Terms of service',
        url: '/terms_of_service',
      },
      {
        label: 'Privacy Policy',
        url: '/privacy_policy',
      },
      {
        label: 'Cookie Policy',
        url: '/cookie_policy',
      },
      {
        label: 'Disclaimer',
        url: '/disclaimer',
      },
      {
        label: 'Manage cookie preference',
        handler: () => {
          if (window?.displayPreferenceModal) {
            window.displayPreferenceModal();
          }
        },
        contentType: 'button',
      },
    ],
  },
];

function splitArrayIntoChunksOfLen(arr, len) {
  var chunks = [],
    i = 0,
    n = arr.length;
  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }
  return chunks;
}

export default function Footer() {
  const copyrightsYear = new Date().getFullYear().toString();
  return (
    <footer>
      <div className={classes.helpsection}>
        <div className={classes.infoSection}>
          <img src={logo} className={classes.footerLogo} alt="Experts Circle - Hiring redefined" />
          <Typography variant="h5">
            A private limited company registered in the United Kingdom, company registration number
            is 12193805.
          </Typography>
        </div>

        <div className={classes.helpfulLinksList}>
          {helpLinks.map((item) => {
            const cols = splitArrayIntoChunksOfLen(item.items, 3);
            return (
              <div key={item.title} className={classes.helpfulLinkGorup}>
                <Typography variant="h4">{item.title}</Typography>
                <div className={classes.row}>
                  {cols.map((col, index) => {
                    return (
                      <div key={index} className={classes.col}>
                        {col.map((linkItem) => {
                          if (linkItem.contentType === 'button') {
                            return (
                              <button key={linkItem.label} type="button" onClick={linkItem.handler}>
                                <Typography variant="h5" noGutter>
                                  {linkItem.label}
                                </Typography>
                              </button>
                            );
                          }
                          return (
                            <Link key={linkItem.url} to={linkItem.url}>
                              <Typography variant="h5" noGutter>
                                {linkItem.label}
                              </Typography>
                            </Link>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>

        <div className={classes.socialLinks}>
          <Typography variant="h4">Connected with us</Typography>
          <div>
            <a
              href="https://www.facebook.com/experts.circle.ltd"
              target="_blank"
              rel="noreferrer"
              className={classes.socialLinksItem}
            >
              <FacebookIcon />
            </a>
            <a
              href="https://twitter.com/expertscircle"
              target="_blank"
              rel="noreferrer"
              className={classes.socialLinksItem}
            >
              <TwitterIcon />
            </a>
            <a
              href="https://www.linkedin.com/company/74367916/admin/"
              target="_blank"
              rel="noreferrer"
              className={classes.socialLinksItem}
            >
              <LinkedinIcon />
            </a>
          </div>
        </div>
      </div>
      <div className={classes.copyright}>
        <Typography variant="h5" align="center">
          Copyright © {copyrightsYear} Experts Circle. All rights reserved.
        </Typography>
      </div>
    </footer>
  );
}
