import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';

import * as classes from './header.module.scss';
import logo from '../../../images/logo.svg';
import menuIcon from '../../../images/menu.svg';
import { Link } from 'gatsby';
import { getDomainURL,employer, windowlocation, windowPathName, candidateUserType, recruiterUserType} from '../../../constants';

const navItems = [
  {
    label: 'How it works',
    url: '/how_it_works',
  },
  {
    label: 'About us',
    url: '/about',
  },
  // {
  //   label: 'Blog',
  //   url: '/blogs',
  // },
  {
    label: 'FAQ',
    url: '/faq',
  },
  {
    label: 'Contact us',
    url: '/contact',
  },
];

export default function Header({ mode }) {
  const [open, setOpen] = useState(false);
  const currentPath = windowPathName;
  const location = windowlocation;

  const toggleOpen = () => setOpen((state) => !state);

  return (
    <header className={classes.headerContainer}>
      <div className={classes.header}>
        <div className={classes.logo}>
          <Link to="/">
            <img src={logo} alt="Experts Circle - Hiring redefined" />
          </Link>
        </div>

        {mode !== "empty" && (
          <>
            <nav className={classes.desktopNavs}>
              {navItems.map((navItem) => {
                return (
                  <Link
                    className={
                      currentPath === navItem.url ? classes.active : ""
                    }
                    key={navItem.url}
                    to={navItem.url}
                  >
                    {navItem.label}
                  </Link>
                );
              })}
            </nav>

            {mode !== "expert_home" && (
              <div className={classes.desktopMainActions}>
                <Button
                  onClick={() => {
                    window.location = getDomainURL(location, employer);
                  }}
                  className={classes.employeBut}
                  variant="contained"
                >
                  Employers
                </Button>
                <Button
                  onClick={() => {
                    window.location = getDomainURL(location, recruiterUserType);
                  }}
                  color="secondary"
                  className={classes.expertBut}
                  variant="contained"
                >
                  Experts
                </Button>
              </div>
            )}

            <button
              onClick={() => {
                toggleOpen();
              }}
              className={classes.mobileDrawerBut}
            >
              <img src={menuIcon} alt="Experts Circle" />
            </button>
            <Drawer anchor="right" onClose={toggleOpen} open={open}>
              <div className={classes.drawer}>
                <div className={classes.drawerHeader}>
                  <div className={classes.drawerLogo}>
                    <img src={logo} alt="Experts Circle" />
                  </div>
                  <button
                    className={classes.closeButton}
                    onClick={toggleOpen} 
                    aria-label="Close"
                  >
                    &times;
                  </button>
                </div>
                <nav>
                  {navItems.map((navItem) => {
                    return (
                      <Link key={navItem.url} to={navItem.url}>
                        {navItem.label}
                      </Link>
                    );
                  })}
                  {mode !== "expert_home" && (
                    <div className={classes.drawerMainActions}>
                      <Button
                        onClick={() => {
                          window.location = getDomainURL(location, employer);
                        }}
                        className={classes.employeBut}
                        variant="contained"
                      >
                        Employers
                      </Button>
                      <Button
                        onClick={() => {
                          window.location = getDomainURL(
                            location,
                            recruiterUserType
                          );
                        }}
                        color="secondary"
                        className={classes.expertBut}
                        variant="contained"
                      >
                        Experts
                      </Button>
                    </div>
                  )}
                </nav>
              </div>
            </Drawer>
          </>
        )}
      </div>
    </header>
  );
}
