import React from 'react'
import { useMediaQuery } from '@mui/material'
import * as classes from '../../styles/HowItWorkSection.module.scss'
import InfoCard from './InfoCard'
import imgsrc from '../../images/whywe.svg'
import section1img from "../../images/hiw_section1.svg";
import WhyJoinAsExpert from "./WhyJoinAsExpert";

export default function HowItWorkSection() {

  return (
   <>
      <InfoCard 
        title="Who Is It For?"
        infoText={
          <>
              Experts Circle is for professionals who want to make a difference while leveraging their knowledge and network. This includes:            
              <ul className={classes.stepList}>
              <li><strong>Industry Experts: </strong>Professionals with deep knowledge in their field and a strong understanding of what it takes to succeed in specific roles.</li>
              <li><strong>Experienced Employees: </strong>Current or former employees with insider knowledge of job openings in their organisations.</li>
              <li><strong>Well-Connected Individuals: </strong>People with extensive professional networks who can identify and recommend top talent for specific opportunities.</li>
            </ul>
          </> 
        }       
        img={imgsrc} 
      />
        
      <WhyJoinAsExpert/>

          <InfoCard 
        title="How It Works?"
        infoText={
          <>
            Dive into our streamlined process where experts, like you, can transform professional connections into successful recommendations. Here's a glimpse of how it unfolds:
            <ul className={classes.stepList}>
              <li><strong>Identify Opportunities: </strong>Discover job openings within your company or professional network that match the skills of your connections.</li>
              <li><strong>Recommend Talent: </strong>Recommend Talent: Use your expertise to suggest individuals who are best suited for the role. Our platform verifies and screens their profiles.</li>
              <li><strong>Get Rewarded: </strong>Once your recommended candidate is hired, you receive a significant share of the reward. The candidate also benefits from the success.</li>
            </ul>
          </> 
        }       
        img={section1img} 
      />      
    </>
  )
}
