import React from 'react';
import Typography from '../../components/Typography/Typography';
import * as classes from './WhyWeSection.module.scss';

export default function WhyJoinAsExpert() {
  return (
    <div className={classes.whyWeSection}>
      <Typography variant="h1" align="center">
        Why Join Experts Circle?
      </Typography>

        <Typography variant="p">
        <br/>
          <p><b>Become a Trusted Expert</b>: Leverage your industry knowledge and professional connections to create new opportunities for yourself and others. 
            This role is designed to complement your existing career, offering a way to grow professionally without disrupting your primary job.</p>
          <ul>
            <li><b>Built for Experts: </b> We empower industry professionals to connect businesses with the right talent using their insider knowledge.</li>
            <li><b>Verified and Trusted: </b>As a verified expert, your recommendations carry weight and credibility, giving businesses confidence in the candidates you suggest.</li>
            <li><b>Your Rewards: </b> Earn the majority of the recruitment fee for successful placements, with only a small percentage retained by the platform to sustain and improve services. </li>      
            <li><b>Simple and Transparent: </b> Everything you need is in one place. Track recommendations, communicate with businesses, and manage your earnings seamlessly. </li>          
          </ul>          
        </Typography>      
    </div>
  );
}
