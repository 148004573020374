import React from 'react';
import './CallToAction.scss';
import Button from "@mui/material/Button";
import { navigate } from "gatsby";

// eslint-disable-next-line react/prop-types
const CallToAction = ({ onButtonClick }) => {
  return (
    <div className="cta-container">
      <h2 className="cta-title">Start Making an Impact Today</h2>
      <div className="cta-buttons">
        <a onClick={onButtonClick} className="cta-button primary">Get Started</a>
        <Button onClick={() => navigate("/how_it_works")} className="cta-button secondary">Learn More</Button>
      </div>    
      </div>
  );
};

export default CallToAction;